import { Api } from '@/libraries/api'

async function addImage(formData) {
  const apiPath = `/extra/file`
  const apiResult = await Api().post(apiPath, formData)
  const { result, url } = apiResult.data
  let returnUploadedUrl = ''
  if (result === true) {
    returnUploadedUrl = url
  }
  return returnUploadedUrl
}

export default {
  addImage
}
