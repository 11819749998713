<template>
  <form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`${promotion_type_text} ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <InformationComponent
            type="info"
            :messages="promotion_type === 'popup' ? informationTextPopup : informationTextBanner"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          이미지 파일 업로드
        </v-col>
        <!-- <v-col cols="12">
      </v-col> -->
        <v-col cols="12">
          {{ promotion_type_text }} 이미지
          <FilePond
            ref="pond1"
            name="upload1"
            label-idle="Drop files here..."
            :allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="null"
            :instant-upload="false"
            :files="uploadFile1"
            @init="handleFilePondInit"
          />
        </v-col>
        <v-col
          v-show="promotion_type === 'banner'"
          cols="12"
        >
          전면 광고 이미지
          <FilePond
            ref="pond2"
            name="upload2"
            label-idle="Drop files here..."
            :allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="null"
            :instant-upload="false"
            :files="uploadFile2"
            @init="handleFilePondInit"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-5"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="mode==='add'"
            class="mx-4"
            color="primary"
            @click="addItem"
          >
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">등록</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">수정</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
          <v-btn
            color=""
            class="mx-4"
            @click="backToListPage"
          >
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="deleteConfirmSheetView"
      persistent
    >
      <v-sheet
        class="text-center"
        height="250px"
      >
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn
            class="mx-3"
            color="primary"
            @click="deleteItem"
          >
            <span>Y</span>
          </v-btn>
          <v-btn
            class="mx-3"
            color=""
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </form>
</template>

<script>
// Import FilePond styles
import "filepond/dist/filepond.min.css"

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"

// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Import Vue FilePond
import vueFilePond from "vue-filepond"

import PageTitleComponent from '../_elements/title/PageTitle'
import FileUploadApi from '../_common/fileUploadApi'
import InformationComponent from '../_elements/textbox/Information'
import Api from './api/advertisementApi'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
// FilePond.setOptions({

// })

export default {
  components: {
    PageTitleComponent,
    FilePond,
    InformationComponent
  },
  data() {
    return {
      mode: '',
      mode_text: '',
      promotion_type: '',
      promotion_type_text: '',
      deleteConfirmSheetView: false,
      uploadFile1: [],
      uploadFile2: [],
      idx: undefined,
      item: {
        idx: 0,
        admin_idx: 0,
        created_at: "2021-07-08T05:51:59",
        image_url1: "",
        image_url2: "",
        promotion_type: "null" // popup or banner
      },
      informationTextPopup: [`* 이미지 등록 가이드 : 최대 498*439 png, jpg`],
      informationTextBanner: [
        `* 배너 썸네일 이미지 등록 가이드 : 최대 1656*969 png, jpg`,
        `* 전면 광고 이미지 등록 가이드 : 최대 1656*2553 png, jpg`
      ]
    }
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      const { idx, promotionType } = this.$route.params
      this.promotion_type = promotionType
      this.promotion_type_text = { banner: '베너', popup: '팝업' }[promotionType]
      if (idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
        this.item.promotion_type = promotionType
      } else {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.idx = parseInt(idx, 10)
        this.getItem()
      }
    },
    handleFilePondInit() {
      // console.log("FilePond has initialized")
      // FilePond instance methods are available on `this.$refs.pond`
    },

    async attachCurrentTime(filename) {
      return `${filename}?${(new Date()).getTime()}`
    },

    async getItem() {
      const result = await Api.getItem(this.idx)
      this.item = result
      // await this.$refs.pond1.addFile(await this.attachCurrentTime(this.item.image_url1))
      // await this.$refs.pond2.addFile(await this.attachCurrentTime(this.item.image_url2))
      this.uploadFile1 = [await this.attachCurrentTime(this.item.image_url1)]
      this.uploadFile2 = [await this.attachCurrentTime(this.item.image_url2)]
    },

    async uploadImage(pondNumber = 0) {
      let files = []
      if (pondNumber === 1) {
        files = this.$refs.pond1.getFiles()
      } else if (pondNumber === 2) {
        files = this.$refs.pond2.getFiles()
      }

      if (files.length === 0) {
        this.$bus.$emit('warningMessage', '등록할 이미지가 없습니다')
        return false
      }

      const formData = new FormData()
      formData.append('file', files[0].file)
      const uploadedFileUrl = await FileUploadApi.addImage(formData)

      if (uploadedFileUrl === '') {
        this.$bus.$emit('errorMessage', '이미지 등록에 실패하였습니다')
        return false
      }

      return uploadedFileUrl
    },

    async addItem() {
      const data = {
        promotion_type: this.promotion_type,
        image_url1: await this.uploadImage(1),
        image_url2: ''
      }

      if (this.promotion_type === 'banner') {
        data.image_url2 = await this.uploadImage(2)
      }

      const result = await Api.addItem(data)

      if (result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },

    async updateItem() {
      const data = {
        image_url1: await this.uploadImage(1),
        image_url2: ''
      }
      if (this.promotion_type === 'banner') {
        data.image_url2 = await this.uploadImage(2)
      }
      const result = await Api.updateItem(this.idx, data)
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const result = await Api.deleteItem(this.idx)
      if (result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },

    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Advertisement.Items') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/service/advertisement/${this.$route.params.promotionType}/items`)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.filepond--wrapper {
  ::v-deep .filepond--credits {
    display: none;
  }
}
</style>
