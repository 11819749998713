import { Api, makeGetUrl } from '@/libraries/api'

/*
  GET
  /admin/promotion
  Get All Promotions

  POST
  /admin/promotion
  Create Promotion

  GET
  /admin/promotion/{promotion_idx}
  Get Promotion By Type

  DELETE
  /admin/promotion/{promotion_idx}
  Delete Promotion By Idx

  GET
  /admin/promotion/type/{promotion_type}
*/

async function getItems(promotionType = '', page = 1, limit = 10, query = '') {
  const url = `/admin/promotion/type/${promotionType}`
  const filters = []
  // filters.push(`start_date=2021-05-01`)
  // filters.push(`end_date=2021-08-01`)
  const params = {
    page,
    limit,
    query,
    filters: filters.join('|')
  }
  const apiResult = await Api().get(makeGetUrl(url, params))
  const { items, pagination } = apiResult.data.data
  return { items, pagination }
}

async function getItem(idx) {
  const url = `/admin/promotion/${idx}`
  const apiResult = await Api().get(url)
  const { item } = apiResult.data.data
  return item
}

async function addItem(data) {
  const url = `/admin/promotion`
  const apiResult = await Api().post(url, data)
  const { result } = apiResult.data
  return result
}

async function updateItem(idx, data) {
  const url = `/admin/promotion/${idx}`
  const apiResult = await Api().put(url, data)
  const { result } = apiResult.data
  return result
}

async function deleteItem(idx) {
  const url = `/admin/promotion/${idx}`
  const apiResult = await Api().delete(url)
  const { result } = apiResult.data
  return result
}

async function changeOrder(promotionType, idxList) {
  const url = `/admin/promotion/type/${promotionType}`
  const data = {
    promotion_item: [...idxList]
  }
  const apiResult = await Api().put(url, data)
  const { result } = apiResult.data
  return result
}

export default {
  getItems,
  getItem,
  addItem,
  updateItem,
  deleteItem,
  changeOrder
}
